import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAppDispatch } from '../redux/store';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import qs from 'qs';
import blackLogo from '../assets/img/black.log.png';
import { usersSelector } from '../redux/users/selectors';
import { signinUser, usersSlice, clearState } from '../redux/users/slice';
import { createNull } from 'typescript';
// @ts-ignore
import MetaTags from 'react-meta-tags';
import Logo from "../assets/img/MouseWait.png";


type FormData = {
  password: string;
  username: string;
  loginfrom: string;
};

interface LocationState {
  returnTo?: string;
  source?: string;
}

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
  const state = location.state as LocationState;

  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>();
  const [error, SetError] = useState<any | null>(null);
  const [sucess, SetSucess] = useState<any | null>(null);
  const [showAlert, SetShowAlert] = useState<any | null>(false);
  const { isFetching, isSuccess, isError, errorMessage } =
    useSelector(usersSelector);
  const onSubmit = (data: any) => {
    dispatch<any>(signinUser(data)).then((res: any) => {
      // console.log(res);
    });
  };

  useEffect(() => {
    console.log('Login component mounted');
    console.log('Current URL:', window.location.href);
    console.log('Referrer:', document.referrer);
    console.log('Navigation state:', state);
    console.log('Auth state on mount:', {
      token: localStorage.getItem('token'),
      isSuccess,
      isError,
      errorMessage,
      accessFrom: localStorage.getItem('access')
    });
    
    if (localStorage.getItem('access') === 'app') {
      console.log('Detected app access - showing alert and redirecting');
      window.alert("Please login from the Me tab in the MouseWait App, or the menu icon on the top right, thanks!");
      
      // If we have a return path, use it for navigation back
      if (state?.returnTo) {
        console.log('Returning to:', state.returnTo);
        navigate(state.returnTo);
      } else {
        navigate(-1); // Fall back to previous page
      }
    }
  }, [localStorage.getItem('access'), state, navigate]);

  useEffect(() => {
    if (isSuccess) {
      console.log('Login successful, handling navigation');
      dispatch(clearState());
      SetSucess(errorMessage);
      SetError(null);
      SetShowAlert(true);
      reset();
      
      // If we have a return path, use it
      if (state?.returnTo) {
        console.log('Navigating to return path:', state.returnTo);
        navigate(state.returnTo);
      } else {
        navigate('/disneyland/lounge/');
      }
    }
    if (isError) {
      console.log('Login error:', errorMessage);
      dispatch(clearState());
      reset();
      SetError(errorMessage);
      SetShowAlert(true);
    }
  }, [isSuccess, isError, errorMessage, state, navigate]);

  const accessfrom = localStorage.getItem('access');
  console.log('Login component - accessfrom:', accessfrom);

  // Don't render the login page if accessed from app
  if (accessfrom === 'app') {
    return null;
  }

  return (
    <>
      <>

      <MetaTags>
          <title>Login - Mousewait </title>
          <meta
            name='description'
            content="login in the mouswait for access lounge "
          />
          <meta property='og:title' content='Login - Mousewait' />
          
          <meta
            property='og:image'
            content='https://mousewait.com/static/media/image.png'
          />
          <meta
            property='og:description'
            content='Login in the mousewait'/>
        </MetaTags>
        <div className='des-bg'>
          {/*===== MW-banner-sec Start =======*/}
          <section className='MW-signpage'>
            <div className='container'>
              <div className='row'>
                <div className='Sign-bg'>
                  <div className='Mw-Sign text-center'>
                    <Link to='/disneyland/lounge'>
                      <img
                          src={blackLogo}
                          className='img-fluid'
                          alt='MouseWait-logo'
                      />
                    </Link>
                    
                    <h3>Welcome Back</h3>
                  </div>

                  <form
                    className='space-y-6'
                    onSubmit={handleSubmit(onSubmit)}
                    method='POST'
                  >
                    <div className='sign-from'>
                      <div className='form-floating mb-3'>
                        <input
                          type='email'
                          className='form-control'
                          id='floatingInput'
                          placeholder='mw-email'
                          {...register('username')}
                        />
                        <label htmlFor='floatingInput'>
                          <i className='fas fa-user' /> Username
                        </label>
                      </div>
                      <div className='form-floating'>
                        <input
                          type='password'
                          className='form-control'
                          id='floatingPassword'
                          placeholder='Password'
                          {...register('password')}
                        />
                        <label htmlFor='floatingPassword'>
                          <i className='fas fa-lock' /> Password
                        </label>
                      </div>
                      <input
                        type='hidden'
                        className='form-control'
                        id='floatingInput'
                        placeholder='mw-email'
                        defaultValue={'webview'}
                        {...register('loginfrom')}
                      />
                    </div>
                  </form>
                  <div className='sign-btn text-center'>
                    <button
                      type='button'
                      onClick={handleSubmit(onSubmit)}
                      className='MW-btn'
                    >
                      Let's Go!
                    </button>
                    <h6>
                      <Link to='/forgotpassword'>
                        Forgot Username/Password?
                      </Link>
                    </h6>
                    <h6>
                      <Link to='/signup'>Create New Account</Link>
                    </h6>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </section>
        </div>
      </>
    </>
  );
};

export default Login;

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { setSortByTime } from '../redux/lounges/slice';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../redux/store';
import midBanner from '../assets/img/mid-banner-img.png';
import { Search } from '../components/Search';
import * as Sentry from '@sentry/react';
import '../css/responsive.css';

export const LoungeHeader = () => {
  const [stTime, SetStTime] = useState<any>(false);
  const srtvalue = localStorage.getItem('shortByTime');
  const loungeland = localStorage.getItem('loungeland');
  const getpagename = localStorage.getItem('pagename');
  const dispatch = useAppDispatch();
  const location = useLocation();
  useEffect(() => {
    srtvalue == null ? SetStTime(false) : SetStTime(srtvalue);
  }, [srtvalue]);
  const onSoryTime = (sorTBY: any) => {
    SetStTime(sorTBY);
    localStorage.setItem('shortByTime', sorTBY);
    let SortTimeType: any = sorTBY;
    let sortingTime: any = null;

    dispatch(setSortByTime({ SortTimeType }));
  };
  const navigate = useNavigate()
  
  const pageReload = () => {
    console.log('pageReload function called');
    console.log('Current path:', window.location.pathname);
    window.scrollTo(0, 0);
    // Force a full page refresh instead of just navigation
    window.location.reload();
  }

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('Logo clicked');
    pageReload();
  };

  let pathArray = window.location.pathname.split( '/' );

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check if user is authenticated (you might want to replace this with your actual auth check)
    const token = localStorage.getItem('token');
    console.log('Auth check - Token:', token);
    setIsAuthenticated(!!token);
  }, []);

  const handleClub333Click = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    console.log('Club 333 link clicked');
    console.log('Is authenticated:', isAuthenticated);
    console.log('Current pathname:', window.location.pathname);
    console.log('Access from:', localStorage.getItem('access'));
    console.log('User Agent:', window.navigator.userAgent);
    
    // Check if we're in the app environment
    const isInApp = localStorage.getItem('access') === 'app';
    console.log('Is in app:', isInApp);
    
    if (isInApp) {
      console.log('Detected app environment - handling app-specific navigation');
      if (!isAuthenticated) {
        console.log('App user not authenticated, showing login message');
        window.alert("Please login from the Me tab in the MouseWait App first");
        return;
      }
    }

    if (!isAuthenticated) {
      console.log('User not authenticated, redirecting to login');
      navigate('/disneyland/login', { 
        state: { 
          returnTo: '/club333',
          source: localStorage.getItem('access')
        } 
      });
      return;
    }

    // If authenticated, proceed to Club 333
    console.log('User authenticated, proceeding to Club 333');
    window.location.href = '/club333';
  };

  const handleLoungeLandClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    console.log('Lounge.land link clicked');
    console.log('Is authenticated:', isAuthenticated);
    console.log('Current pathname:', window.location.pathname);
    console.log('Access from:', localStorage.getItem('access'));
    console.log('User Agent:', window.navigator.userAgent);
    
    const isInApp = localStorage.getItem('access') === 'app';
    console.log('Is in app:', isInApp);
    
    if (isInApp) {
      console.log('Detected app environment - handling app-specific navigation');
      if (!isAuthenticated) {
        console.log('App user not authenticated, showing login message');
        window.alert("Please login from the Me tab in the MouseWait App first");
        return;
      }
    }

    if (!isAuthenticated) {
      console.log('User not authenticated, redirecting to login');
      navigate('/disneyland/login', { 
        state: { 
          returnTo: '/loungeland',
          source: localStorage.getItem('access')
        } 
      });
      return;
    }

    // If authenticated, proceed to lounge.land
    console.log('User authenticated, proceeding to lounge.land');
    window.location.href = '/loungeland';
  };

  return (
    <>
      <div className='banner-img'>
        {
          (pathArray[1] == 'disneyworld') ? 
          <>
            <img src={midBanner} className='img-fluid hellobhai' alt='mid-banner-img' />
            <Link to='/disneyworld/lounge' className="banner-logo"></Link>
          </>
          : 
          <>
            <img src={midBanner} className='img-fluid' alt='mid-banner-img' onClick={pageReload} style={{cursor: 'pointer'}}/>
            <Link to='/disneyland/lounge/' className="banner-logo" onClick={handleLogoClick}></Link>
          </>
        }
      </div>
      <div className='top-trend'>
        <div className='text-head-trend text-center'>
        <h6 className='trending-t'>
        {/* <a href="/disneyland/m/L/most-viewed" target="_self" rel="noopener noreferrer">Best of the Month</a>
    <span className="separator"> | </span> */}
    <a href="/club333" onClick={handleClub333Click} target="_self" rel="noopener noreferrer">Club 333</a>
    <span className="separator"> | </span>
    <a href="/disneyland/user/18/mypost" target="_self" rel="noopener noreferrer">Admin Updates</a>
    <span className="separator"> | </span>
    <a href="/loungeland" onClick={handleLoungeLandClick} target="_self" rel="noopener noreferrer">lounge.land</a>
    <span className="separator"> | </span>
    <a href="/disneyland/tag/food/" target="_self" rel="noopener noreferrer">Food Blog</a>
    {/* <span className="separator"> | </span>
    <a href="https://lounge.land/all-access/" target="_new" rel="noopener noreferrer">ALL-ACCESS</a> */}
</h6>

          <h6 className='trending-section-new'>
            {/*   {getpagename} (the name of the Land) */}
            {getpagename != '' ? <> {getpagename}</> : <> Disneyland Lounge</>}
          </h6>
          <Search />
        </div>
      </div>

    </>
  );
};
